import axios from "axios";
import {getApiUrlWithVersion} from "./environmentService";
import {
  NewsletterEdition,
  NewsletterEditionLink,
  PostNewsletterEditionInput,
  PutNewsletterEditionInput,
} from "../types/newsletterEdition";
import {getAuthUser} from "./authService";

/**
 * This component is the integration point between the frontend and the backend for campaigns and newsletter editions.
 */
class CampaignService {
  // TODO: take in a campaignId to manage all actions for that campaign
  constructor() {}

  private getAuthHeaders(): object {
    return {
      Authorization: getAuthUser()?.accessToken,
    };
  }

  async getNewsletterEdition(
    newsletterEditionId: string,
  ): Promise<NewsletterEdition> {
    const response = await axios.get(
      `${getApiUrlWithVersion()}/newsletter_edition/${newsletterEditionId}`,
      {headers: this.getAuthHeaders()},
    );
    const parsedData = response.data;
    // parsedData.email_template = JSON.parse(parsedData.email_template);
    parsedData.id = newsletterEditionId;
    // parsedData.email_template = JSON.parse(parsedData.email_template);
    return parsedData;
  }

  async getNewsletterEditionTemplate(
    newsletterEditionId: string,
  ): Promise<object> {
    const newsletterEdition: NewsletterEdition =
      await this.getNewsletterEdition(newsletterEditionId);
    return newsletterEdition.email_template;
  }

  /**
   * List all newsletter editions for the current user.
   */
  async listNewsletterEditions(): Promise<NewsletterEdition[]> {
    const response = await axios.get(
      `${getApiUrlWithVersion()}/newsletter_edition`,
      {headers: this.getAuthHeaders()},
    );
    return response.data;
  }

  /**
   * Create a new newsletter edition.
   *
   * Returns the id of the created newsletter edition.
   */
  async createNewsletterEdition(
    postNewsletterEditionInput: PostNewsletterEditionInput,
  ): Promise<string> {
    const response = await axios.post(
      `${getApiUrlWithVersion()}/newsletter_edition`,
      postNewsletterEditionInput,
      {headers: this.getAuthHeaders()},
    );
    return response.data.id;
  }

  /**
   * Update a newsletter edition.
   */
  async updateNewsletterEdition(
    newsletterEditionId: string,
    newsletterEdition: PutNewsletterEditionInput,
  ): Promise<NewsletterEdition> {
    const response = await axios.put(
      `${getApiUrlWithVersion()}/newsletter_edition/${newsletterEditionId}`,
      newsletterEdition,
      {headers: this.getAuthHeaders()},
    );
    return response.data;
  }

  /**
   * Delete a newsletter edition.
   *
   * Returns an error on failure, and 204 No Content on success.
   */
  async deleteNewsletterEdition(newsletterEditionId: string): Promise<any> {
    const response = await axios.delete(
      `${getApiUrlWithVersion()}/newsletter_edition/${newsletterEditionId}`,
      {headers: this.getAuthHeaders()},
    );
    return response.data;
  }

  /**
   * Update a newsletter edition's links.
   */
  async updateNewsletterEditionLinks(
    newsletterEditionId: string,
    newsletterEditionLinks: NewsletterEditionLink[],
  ): Promise<NewsletterEdition> {
    const response = await axios.put(
      `${getApiUrlWithVersion()}/newsletter_edition/${newsletterEditionId}/links`,
      {
        links: newsletterEditionLinks,
      },
      {
        headers: this.getAuthHeaders(),
      },
    );
    return response.data;
  }

  /**
   * Get a BeeFree plugin session token.
   */
  async getBeeFreeToken(): Promise<string> {
    const response = await axios.get(
      `${getApiUrlWithVersion()}/template_editor/session_key`,
      {headers: this.getAuthHeaders()},
    );
    return response.data.access_token;
  }
}

const campaignService = new CampaignService();
export default campaignService;
