import {AccordionOption} from "./Types";

export const EVENTS = "Events";

export enum CONTENT_TYPES {
  EVENTS = "EVENTS",
  NEWS = "NEWS",
  TESTIMONIALS = "COMMUNITY_CONTENT",
  JOBS = "JOBS",
}

export const ONBOARDING_ACCORDION_CONTENT: Record<
  CONTENT_TYPES,
  AccordionOption
> = {
  JOBS: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Include this section if you'd like to provide job opportunities for your audience.</li>
            <li>Aimply will curate relevant and timely job postings for your newsletter automatically.</li>
        </ul>
        `,
    label: "Jobs & Careers",
  },
  NEWS: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Include this section if you'd like to provide news, blogs, and research for your audience.</li>
            <li>Aimply will curate, summarize, and add relevant links and content to your newsletter automatically.</li>
            <li>You can also provide links you'd like us to include in your newsletter and we will handle the rest.</li>
        </ul>
        `,
    label: "News & Research",
  },
  COMMUNITY_CONTENT: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Community content is any content sourced from your existing readers.</li>
            <li>If you select this, Aimply will ask your audience for things testimonials, content they would like featured, and Q&A that you can answer.</li>
            <li>This is often best for newsletters with an existing audience.</li>
        </ul>
        `,
    label: "Community Content",
  },
  EVENTS: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Include this section if you'd like to provide events for your audience.</li>
            <li>If you select this, Aimply will find and curate relevant events for your newsletter automatically.</li>
            <li>You can also provide links to your upcoming events and we will include them in your newsletter.</li>
        </ul>
        `,
    label: "Events",
  },
};

export const formDataKeyToDisplayName: Record<string, string> = {
  email: "Email",
  companyWebsite: "Company Website",
  audienceOfOne: "Audience of One",
  sampleEvents: "Sample Events",
  sampleNews: "Sample News",
  sampleTestimonials: "Sample Testimonals",
  sampleJobs: "Sample Jobs",
};
