import React, { ReactNode } from "react";

export interface SidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const SidePanel: React.FC<SidePanelProps> = ({ isOpen, onClose, children }) => {

  return (
    <div>
      <div className={`side-panel ${isOpen ? 'open' : ''}`}>
        <button className="side-panel-close-button" onClick={onClose}>X</button>
        <div style={{ padding: "20px" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
