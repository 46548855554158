import { record } from 'rrweb';
import { v4 as uuidv4 } from 'uuid';
import { getApiUrlWithVersion } from '../services/environmentService';
import axios from 'axios';
import { getAuthUser } from '../services/authService';


interface ScreenRecordingData {
    name: string;
    userId: string;
    sessionId: string;
    sequenceId: number;
    events: { timestamp: number; delay?: number; }[];
}


const uploadScreenRecording = async ({
    name,
    userId,
    sessionId,
    sequenceId,
    events
}: ScreenRecordingData) => {
    const uploadEndpoint = `${getApiUrlWithVersion()}/screen_recording`;
    const data = { name, user_id: userId, session_id: sessionId, sequence_id: sequenceId, events };

    const accessToken = getAuthUser()?.accessToken;

    axios.post(uploadEndpoint, data, { headers: { 'Authorization': accessToken } })
    .then(_ => console.log(`Screen recording uploaded: ${sessionId}, seq: ${sequenceId}`))
    .catch(error => console.error(`Error uploading screen recording: ${error}`));
    
}

/**
 * A component that collects screen recording events and uploads them to the specified S3 bucket.
 */
class RecordingSession {
    // this is where we collect events that are collected by rrweb periodically
    private events: { timestamp: number; delay?: number; }[] = [];
    private name: string;
    private userId: string;
    private sessionId: string;
    private sequenceId: number;
    private stopRecording?: () => void;
    private startedAt?: number;
    private uploadIntervalMs: number = 5000; // we upload every 5 seconds
    private setInterval?: NodeJS.Timeout;

    /**
     * 
     * @param name what this recording session is for
     * @param userId the user id that this recording session is for
     */
    constructor(name: string, userId: string) {
        this.name = name;
        this.userId = userId;
        this.sessionId = `${name}-${uuidv4()}-user_${userId}`;
        this.sequenceId = 0;
    }
    /**
     * Upload the current collection of events to the server
     */
    private uploadToServer() {
        // let's not upload empty events
        if (this.events.length === 0) return;

        const data = {
            name: this.name,
            userId: this.userId,
            sessionId: this.sessionId,
            sequenceId: this.sequenceId,
            events: this.events
        }
        this.events = [];
        this.sequenceId++;
        
        uploadScreenRecording(data);    
    }

    hasStarted() {
        return this.startedAt !== undefined;
    }

    start() {
        if (this.hasStarted()) throw new Error("Recording already started");

        console.log(`Starting screen recording: ${this.sessionId}`);

        this.startedAt = Date.now();
        this.stopRecording = record({
            emit: (event) => this.events.push(event),
            sampling: {
                mousemove: true,
                mouseInteraction: true,
                input: 'all'
            }
        });

        this.setInterval = setInterval(this.uploadToServer.bind(this), this.uploadIntervalMs);
    }

    stop() {
        if (!this.hasStarted()) throw new Error("Recording not started");

        console.log(`Stopping screen recording: ${this.sessionId}`);

        this.stopRecording?.();
        clearInterval(this.setInterval);
        // make the last upload
        this.uploadToServer();
        this.startedAt = undefined;
        this.setInterval = undefined;
    }
}


// we'll need a "provider" to provide recorder instances because it will be difficult to inject them into components and pass them around
// but we also need to make sure we record all the sessions we want to record

let onboardingRecorder: RecordingSession | undefined;
const getOnboardingRecorder = (userId: string) => {
    if (!onboardingRecorder) {
        onboardingRecorder = new RecordingSession("onboarding", userId);
    }
    return onboardingRecorder;
}

export default getOnboardingRecorder;