import React from "react";
import Header from "./Header/Header";
import Sidebar from "./Sidebar";

interface LayoutProps {
  children: React.ReactNode; // Add a type annotation for the children prop
}

const PageWithLayout: React.FC<LayoutProps> = ({children}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}>
      <Header />
      <div
        style={{
          display: "flex",
          flex: 1,
        }}>
        <Sidebar />
        <main
          style={{
            flex: 1,
            padding: "0px 30px",
            marginLeft: 0,
            backgroundColor: "#f9f9f9",
          }}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default PageWithLayout;
