import React from "react";

interface UrlInputFormProps {
  urls: string[];
  handleUrlsChange: (newUrls: string[]) => void;
  placeholder: string;
}

const UrlInputForm: React.FC<UrlInputFormProps> = ({
  urls,
  handleUrlsChange,
  placeholder,
}) => {
  const handleInputChange = (index: number, value: string) => {
    const newUrls = [...urls];
    newUrls[index] = value;
    handleUrlsChange(newUrls);

    // if the input is deleted, remove it from the array
    if (value.trim() === "" && newUrls.length > 1) {
      newUrls.splice(index, 1);
      handleUrlsChange(newUrls);
    }

    // if the last input has content, add a new empty input
    if (index === urls.length - 1 && value.trim() !== "") {
      handleUrlsChange([...newUrls, ""]);
    }
  };

  return (
    <div className="url-input-container">
      {urls.map((url: string, index: number) => (
        <input
          key={index}
          type="url"
          value={url}
          onChange={e => handleInputChange(index, e.target.value)}
          placeholder={placeholder}
          className="url-input"
        />
      ))}
    </div>
  );
};

export default UrlInputForm;
