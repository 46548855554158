/** Slice for authentication related data. */
import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "../store";
import {jwtDecode} from "jwt-decode";

export interface UserDetails {
  email: string;
}

export interface AuthUser {
  accessToken: string;
  userDetails?: UserDetails;
}

const initialState: AuthUser = {
  accessToken: "",
};

const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    setUserDetails(state, action: PayloadAction<UserDetails>) {
      state.userDetails = action.payload;
    },
    purgeAuthUser(state) {
      state.accessToken = "";
      state.userDetails = undefined;
    }
  },
});

// Actions
export const {setAccessToken, setUserDetails, purgeAuthUser} = authUserSlice.actions;

// Selectors

/**
 * User is authenticated if they have a valid access token and the token has not expired.
 */
export const selectAuthenticated = (state: RootState): boolean => {
  const hasAccessToken = state.authUser.accessToken !== "";
  if (!hasAccessToken) return false;
  const decoded = jwtDecode(state.authUser?.accessToken);
  const accessTokenHasExpired = decoded.exp && decoded.exp < Date.now() / 1000;
  return !accessTokenHasExpired;
}
/**
 * Get the cached access token.
 */
export const selectAccessToken = (state: RootState): string => state.authUser.accessToken;

export const selectUserDetails = (state: RootState): UserDetails | undefined => state.authUser.userDetails;

export const selectUserEmail = createSelector(
    [(state: RootState) => state.authUser.userDetails],
    (userDetails) => userDetails?.email
);

/**
 * Return a boolean indicating if the access token is almost expired. We'll use
 * this info to decide if we should refresh the token.
 * 
 * If true is returned, user is still authenticated and within the token expiration window.
 */
const TOKEN_EXPIRATION_WINDOW_IN_MINUTES = 60; // we'll refresh the token if it expires in the next 60 minutes
export const selectTokenIsAlmostExpired = createSelector(
  [selectAuthenticated, selectAccessToken],
  (authenticated, accessToken) => {
    // if the user is not authenticated, the token is not almost expired - it's fully expired
    if (!authenticated) return false;

    const decoded = jwtDecode(accessToken);
    // consider almost expired if the token expires in the next TOKEN_EXPIRATION_WINDOW_IN_MINUTES minutes
    return decoded.exp && decoded.exp < (Date.now() / 1000) + TOKEN_EXPIRATION_WINDOW_IN_MINUTES * 60;
  }
);


/**
 * Get the user ID (sub) from the access token.
 */
export const selectUserId = createSelector(
  [selectAccessToken],
  (accessToken) => {
    if (accessToken === "") return undefined;
    // decode the access token to get the user ID
    const decoded = jwtDecode(accessToken);
    return decoded.sub;
  }
);

// Reducer
export default authUserSlice.reducer;
