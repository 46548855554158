import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import BeePlugin from "@mailupinc/bee-plugin";
import {CustomRowConfiguration} from "@mailupinc/bee-plugin/dist/types/bee";
import {IToken} from "@mailupinc/bee-plugin/dist/types/bee";
import defaultTemplateJson from "../defaultTemplate.json";
import campaignService from "../services/campaignService";
import {NewsletterEdition} from "../types/newsletterEdition";
import {OnboardingFormProps} from "./Onboarding/Types";
import TemplateEditorSaveModal from "./TemplateEditorSaveModal";
import * as cheerio from 'cheerio';
import OnboardingPopup, {onboardingSteps} from "./Onboarding/steps/OnboardingPopup";
import { selectUserId } from "../store/slices/authUserSlice";
import { useSelector } from "react-redux";

// Assuming OnboardingProps is defined in ./Onboarding/Types
export interface TemplateEditorProps {
  onboardingProps?: OnboardingFormProps;
}

/**
 * The BeeFree plugin needs a token to display.
 */
const fetchBeeFreeToken = async (): Promise<IToken> => {
  const token = await campaignService.getBeeFreeToken();
  return {
    access_token: token,
    token_type: "bearer",
    expires_in: 300,
  } as unknown as IToken;
};

const fetchNewsletterEdition = async (
  newsletterEditionId: string,
): Promise<NewsletterEdition> => {
  return campaignService.getNewsletterEdition(newsletterEditionId);
};

/**
 * Download the current template as an HTML file no additions
 */
const downloadHtml = (html: string, filename: string) => {
  const blob = new Blob([html], {type: "text/html"});
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
};

/**
 * Download the current template as an HTML file that is compatible with Convertkit
 * https://help.kit.com/en/articles/2810363-creating-a-custom-html-email-template
 *
 * ConvertKit requires a "{{ unsubscribe_url }}" to be present in the template
 * Optionally a "{{ subscriber_preferences_url }}" can be present in the template
 * 
 * We search the template and find the last "Unsubscribe" token in the HTML string and add an <a> tag
 * around it to inject the unsubscribe url
 * 
 * If a "Subscription Preferences" token is found, we inject the subscriber preferences url
 * 
 * TODO: let's create a "Custom Row" for this with an unsubscribe button but this requires the BeeFree Core plan: https://docs.beefree.io/beefree-sdk/rows/custom-rows/understanding-custom-rows
 */
const downloadConvertkitHtml = async (html: string, filename: string) => {
  const UNSUBSCRIBE_TOKEN = "unsubscribe";
  const SUBSCRIPTION_PREFERENCES_TOKEN = "subscription preferences";

  // find the last "unsubscribe" token and get its index
  const $ = cheerio.load(html);
  const lastUnsubscribeIndex = $("body").html()!.toLowerCase().lastIndexOf(UNSUBSCRIBE_TOKEN);
  


  // we MUST have an unsubscribe button or <p> to add the unsubscribe url to, if we want to support ConvertKit
  if (lastUnsubscribeIndex === -1) {
    alert("Please add an unsubscribe link to the newsletter edition if you want to send your newsletter with ConvertKit.");
    return;
  }

  $("body").html(
    $("body").html()!.slice(0, lastUnsubscribeIndex) +
    `<a href="{{ unsubscribe_url }}">${$("body").html()!.slice(lastUnsubscribeIndex, lastUnsubscribeIndex + UNSUBSCRIBE_TOKEN.length)}</a>` +
    $("body").html()!.slice(lastUnsubscribeIndex + UNSUBSCRIBE_TOKEN.length)
  );

  // we'll do the same thing for the subscription preferences token
  const lastSubscriptionPreferencesIndex = $("body").html()!.toLowerCase().lastIndexOf(SUBSCRIPTION_PREFERENCES_TOKEN);
  if (lastSubscriptionPreferencesIndex !== -1) {
    $("body").html(
      $("body").html()!.slice(0, lastSubscriptionPreferencesIndex) +
      `<a href="{{ subscriber_preferences_url }}">${$("body").html()!.slice(lastSubscriptionPreferencesIndex, lastSubscriptionPreferencesIndex + SUBSCRIPTION_PREFERENCES_TOKEN.length)}</a>` +
      $("body").html()!.slice(lastSubscriptionPreferencesIndex + SUBSCRIPTION_PREFERENCES_TOKEN.length)
    );
  }


  downloadHtml($.html(), filename);
};

function getNewsletterEditionOnChange(
  setNewsletterEdition: (
    value:
      | ((prevState: NewsletterEdition) => NewsletterEdition)
      | NewsletterEdition,
  ) => void,
) {
  return (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewsletterEdition(prevState => ({...prevState, title: e.target.value}));
}

function showNewsletterEditionString(newsletterEdition: NewsletterEdition) {
  return new Date(newsletterEdition.newsletter_timestamp)
    .toISOString()
    .split("T")[-2];
}

function dateOnchange(
  setNewsletterEdition: (
    value:
      | ((prevState: NewsletterEdition) => NewsletterEdition)
      | NewsletterEdition,
  ) => void,
) {
  return (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewsletterEdition(prevState => ({
      ...prevState,
      newsletter_timestamp: new Date(e.target.value).toISOString(),
    }));
}

/**
 * This is the component where we display the template editor.
 * The template editor is a licensed BeeFree editor.
 */
const TemplateEditor: React.FC<TemplateEditorProps> & {label: string} = () => {
  const {newsletterEditionId} = useParams<{newsletterEditionId?: string}>();
  const navigate = useNavigate();
  const hasOnboardingParam = window.location.href.includes("onboarding");
  const [showOnboarding, setShowOnboarding] = useState(hasOnboardingParam);
  const [currentStep, setCurrentStep] = useState(0);

  // Handlers for navigation between steps
  const handleNextOnobardingStep = () => setCurrentStep(prev => Math.min(prev + 1, onboardingSteps.length - 1));
  const handlePreviousOnboardingStep = () => setCurrentStep(prev => Math.max(prev - 1, 0));
  const handleOnboardingClose = () => setShowOnboarding(false);


  let beeEditor = new BeePlugin(); // we'll have to set this to a new BeePlugin instance but let's just initialize it with a temporary one to keep typing happy

  const latestHtml = useRef<string>("");
  const [isBeeEditorConfigured, setIsBeeEditorConfigured] = useState(false);
  const [isSavingToBackend, setIsSavingToBackend] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // the modal that comes up when we click "save as template"
  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] =
    useState(false);
  // we need this to know if we should let the user export the template or not
  // because the BeeFree plugin calls the onSave callback asynchronously, and if we click "download as HTML" before it's done, it'll download the older version of the template
  const [templateIsSaved, setTemplateIsSaved] = useState(true);

  const userId = useSelector(selectUserId);

  const beeFreeHtmlContainerId = "template-editor-container";
  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString();
  const [newsletterEdition, setNewsletterEdition] = useState<NewsletterEdition>(
    {
      id: "",
      newsletter_timestamp: tomorrow,
      title: `Edit Your Sample Newsletter`,
      created_at: "",
      updated_at: "",
      container: beeFreeHtmlContainerId,
      email_template: defaultTemplateJson,
      status: "",
      newsletter_edition_links: [],
    },
  );

  useEffect(() => {
    if (!newsletterEditionId) return;
    fetchNewsletterEdition(newsletterEditionId).then(setNewsletterEdition);
  }, [newsletterEditionId]);

  const showErrorMessage = (message: string) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(""), 3000);
  };

  const saveToBackend = async (email_template?: object) => {
    setIsSavingToBackend(true);
    await saveToBackend_(email_template)
      .catch(error => {
        console.error("Error saving newsletter edition to backend", error);
        showErrorMessage("Error saving newsletter edition.");
      })
      .finally(() => setIsSavingToBackend(false));
  };

  const saveToBackend_ = useCallback(
    async (email_template?: object) => {
      if (!newsletterEditionId) {
        campaignService
          .createNewsletterEdition({
            email_template: email_template ?? newsletterEdition.email_template,
            title: newsletterEdition.title,
            newsletter_timestamp: newsletterEdition.newsletter_timestamp,
          })
          .then(newNewsletterEditionId => {
            navigate(`/newsletterEdition/${newNewsletterEditionId}`);
          })
          .then(_ => setIsBeeEditorConfigured(false));
      } else {
        campaignService
          .updateNewsletterEdition(newsletterEditionId!, {
            email_template: email_template!,
            title: newsletterEdition.title,
            newsletter_timestamp: newsletterEdition.newsletter_timestamp,
          })
          .then(setNewsletterEdition);
      }
    },
    [newsletterEditionId, newsletterEdition, navigate],
  );

  const handleSave = (template: string, html: string) => {
    setTemplateIsSaved(false);
    latestHtml.current = html;
    const email_template = JSON.parse(template);
    setNewsletterEdition(prevState => ({...prevState, email_template}));
    saveToBackend(email_template).finally(() => setTemplateIsSaved(true));
  };

  const handleSaveAsTemplate = () => {
    setTemplateIsSaved(false);
    beeEditor!.save();
    setIsSaveAsTemplateModalOpen(true);
  };

  const beeFreeConfig = {
    uid: userId,
    container: beeFreeHtmlContainerId,
    language: "en-US",
    onSave: handleSave,
    onSaveAsTemplate: handleSaveAsTemplate,
    onSend: () => {},
    onStart: () => {},
    // rowsConfiguration: {
    //   emptyRows: true,
    //   defaultRows: true,
    // },
  };

  useEffect(() => {
    if (newsletterEditionId && newsletterEdition.id === "") return;
    if (isBeeEditorConfigured) return;

    console.info("Configuring BeeFree editor");

    fetchBeeFreeToken()
      .then(token => {
        beeEditor = new BeePlugin(token);
      })
      .then(_ => {
        beeEditor.start(beeFreeConfig, newsletterEdition.email_template);
      })
      .then(_ => console.info("BeeFree editor configured"))
      .then(_ => setIsBeeEditorConfigured(true))
      .catch(error => console.info("Error configuring BeeFree editor", error));
  }, [beeFreeConfig, newsletterEditionId]);

  return (
    <>
        {showOnboarding && (
            <OnboardingPopup
                onClose={handleOnboardingClose}
                currentStep={currentStep}
                onNext={handleNextOnobardingStep}
                onPrevious={handlePreviousOnboardingStep}
            />
        )}
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          filter: isSaveAsTemplateModalOpen ? "blur(4px)" : "none",
          pointerEvents: isSaveAsTemplateModalOpen ? "none" : "auto",
        }}>
        <div
          className="inline-newsletter-metadata"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <div className="inline-metadata-item" style={{flex: 1}}>
            <input
              type="text"
              value={newsletterEdition.title}
              onChange={getNewsletterEditionOnChange(setNewsletterEdition)}
              style={{
                fontSize: "2em",
                fontWeight: "bold",
                border: "none",
                outline: "none",
                width: "100%",
                backgroundColor: "transparent",
                padding: "0",
                cursor: "text",
              }}
            />
          </div>
          {errorMessage && (
            <div
              style={{
                position: "fixed",
                top: "10px",
                left: "10px",
                zIndex: 1000,
              }}>
              {errorMessage}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}>
            {/*  TODO: Add date picker  */}
            {/*<div className="inline-metadata-item">*/}
            {/*  <label style={{marginRight: "10px"}} htmlFor="date">*/}
            {/*    Prepared By Date*/}
            {/*  </label>*/}
            {/*  <input*/}
            {/*    type="date"*/}
            {/*    value={showNewsletterEditionString(newsletterEdition)}*/}
            {/*    onChange={dateOnchange(setNewsletterEdition)}*/}
            {/*    style={{marginBottom: "10px"}}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
        <div
          id={beeFreeHtmlContainerId}
          style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: 0,
          }}
        />
      </div>
      {isSaveAsTemplateModalOpen && (
        <TemplateEditorSaveModal
          downloadAsHtml={() => {
            downloadHtml(latestHtml.current, `${newsletterEdition.title}.html`);
            setIsSaveAsTemplateModalOpen(false);
          }}
          downloadAsConvertkitHtml={() => {
            downloadConvertkitHtml(
              latestHtml.current,
              `${newsletterEdition.title} for Convertkit.html`,
            );
            setIsSaveAsTemplateModalOpen(false);
          }}
          onClose={() => setIsSaveAsTemplateModalOpen(false)}
          allowExport={templateIsSaved}
        />
      )}
    </>
  );
};

TemplateEditor.label = "Edit Your Template";

export default TemplateEditor;
