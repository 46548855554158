import React, {useEffect, useRef} from "react";
import {OnboardingFormProps} from "../Types";

const WebsiteStep: React.FC<OnboardingFormProps> = ({formData, onChange}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="primary-input-container">
      <h2>Your Website</h2>
      <p>
        We'll fetch your brand assets using this URL to build your newsletter.
      </p>
      <input
        ref={inputRef}
        type="url"
        value={formData.companyWebsite}
        onChange={e => onChange("companyWebsite", e.target.value)}
        placeholder="https://aimply.io"
        className="primary-input"
      />
    </div>
  );
};

export default WebsiteStep;
