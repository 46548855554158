import React, {useState} from "react";
import UrlInputForm from "./UrlInputForm";
import DisclosureText from "./DisclosureText";

const Sources: React.FC = () => {
  const [urls, setUrls] = useState([""]);

  const handleUrlsChange = (newUrls: string[]) => {
    setUrls(newUrls);
  };

  return (
    <div>
      <h1>Sources</h1>

      <div className="info-bubble">
        💡
        <span style={{marginLeft: "8px"}}>
          Specify websites you'd like us to regularly visit to curate content.
        </span>
        <br />
        <br />
        <div style={{paddingLeft: "22px"}}>
          Good examples include:
          <ul style={{marginTop: "8px", marginBottom: "4px"}}>
            <li>An events page for your industry</li>
            <li>Your company's jobs page</li>
            <li>News sources and blogs</li>
          </ul>
        </div>
      </div>

      <UrlInputForm
        urls={urls}
        handleUrlsChange={handleUrlsChange}
        placeholder="https://techcrunch.com"
      />

      <button
        className="primary-button"
        // TODO (jay): save to BE on submit
        // onClick={(e) => saveToNewsletter(urls)}
      >
        Save
      </button>

      <DisclosureText />
    </div>
  );
};

export default Sources;
