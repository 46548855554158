// src/components/Onboarding/steps/TestimonialsSampleContent.tsx
import React from "react";
import {OnboardingFormProps} from "../Types";
import {COMMUNITY_CONTENT_CIO} from "../SampleContent";
import DetailCheckboxes from "./DetailCheckboxes";

const TestimonialsSampleContent: React.FC<OnboardingFormProps> = ({
  formData,
  onChange,
}) => (
  <div>
    <h2>Select Community Content 📝</h2>
    <p>
      Select the sample community content that would make sense in your
      newsletter.
    </p>
    <DetailCheckboxes
      fieldKey="sampleTestimonials"
      formData={formData}
      onChange={onChange}
      detailCheckboxOptions={COMMUNITY_CONTENT_CIO}
    />
  </div>
);

export default TestimonialsSampleContent;
