import React, { useEffect } from "react";
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { runStatsigSessionReplay } from "@statsig/session-replay";
import { useSelector } from "react-redux";
import { selectUserDetails, selectUserId, UserDetails } from "../store/slices/authUserSlice";
import { createSelector } from "@reduxjs/toolkit";
import { IS_PRODUCTION, STATSIG_API_KEY } from "../services/environmentService";

export const selectStatsigUser = createSelector(
    [selectUserId, selectUserDetails],
    (
      userId?: string,
      userDetails?: UserDetails,
    ): {userID: string; email?: string;} => ({
      userID: userId ?? "",
      email: userDetails?.email,
    }),
  );


interface MetricsProviderProps {
    children: React.ReactNode;
}

/**
 * Configure metrics provider. We're using StatSig.
 */
const MetricsProvider: React.FC<MetricsProviderProps> = ({children}) => {
    const statssigEnvironmentTier = IS_PRODUCTION ? 'production' : 'development';

    
    
    const statsigUser = useSelector(selectStatsigUser);
    let { client } = useClientAsyncInit(STATSIG_API_KEY, statsigUser, {
        environment: {tier: statssigEnvironmentTier}, 
    });

    useEffect(() => {
        client.updateUserAsync(statsigUser);
    }, [statsigUser])


      useEffect(() => {
        // optional: remove these lines if you dont want to use one or the other
        runStatsigAutoCapture(client);
        runStatsigSessionReplay(client);
      }, [client]);
      
  return (
    <StatsigProvider client={client}>{children}</StatsigProvider>
  );
};

export default MetricsProvider;