import React from "react";
import {ReactComponent as CheckboxEmpty} from "../../../assets/icons/checkboxEmpty.svg";
import {ReactComponent as CheckboxFull} from "../../../assets/icons/checkboxFull.svg";

const CheckboxSVG = ({
  isChecked,
  handleOnClick,
}: {
  isChecked: boolean;
  handleOnClick: () => void;
}) => (
  <div
    className="emphasis-icon"
    style={{
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      justifyContent: "center",
    }}
    onClick={handleOnClick}>
    {isChecked ? <CheckboxFull /> : <CheckboxEmpty />}
  </div>
);

export default CheckboxSVG;
