import axios from "axios";
import {getApiUrlWithVersion} from "./environmentService";
import {getAuthUser, getCurrentUserEmail} from "./authService";
import {DetailCheckboxOption} from "../components/Onboarding/Types";
import {
  COMMUNITY_CONTENT_CIO, PREPPED_SAMPLE_DATA,
  SAMPLE_EVENTS_CIO,
  SAMPLE_JOBS_CIO,
  SAMPLE_NEWS_CIO
} from "../components/Onboarding/SampleContent";

export class OnboardingService {
  /**
   * Generate a sample newsletter edition with the given context.
   *
   * Returns the ID of the newly created newsletter edition.
   */
  async generateSampleNewsletterEdition(context: object): Promise<string> {
    const response = await axios.post(
      `${getApiUrlWithVersion()}/onboarding/generate_sample_newsletter_edition`,
      {context},
      {headers: this.getAuthHeaders()},
    );
    console.log("Generated sample newsletter edition:", response.data);
    return response.data.newsletter_edition_id;
  }

  // TODO all these functions will need to go async later & dynmically figure out data based on website + reader
  generateSampleJobs(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].jobs;
    }

    return SAMPLE_JOBS_CIO;
  }

  generateSampleEvents(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();
    console.log("In generateSampleEvents", email);

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].events;
    }

    return SAMPLE_EVENTS_CIO;
  }

  generateSampleCommunityContent(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].communityContent;
    }

    return COMMUNITY_CONTENT_CIO;
  }

  generateSampleNews(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].news;
    }

    return SAMPLE_NEWS_CIO;
  }

  private getAuthHeaders(): object {
    return {
      Authorization: getAuthUser()?.accessToken,
    };
  }
}

const onboardingService = new OnboardingService();
export default onboardingService;
