// ProtectedRoute.tsx
import React, {useEffect} from "react";
import {useSearchParams, Outlet, useNavigate} from "react-router-dom";
import {
  ingestPasswordlessAuthToken,
  refreshToken,
} from "../services/authService";
import {
  selectAccessToken,
  selectAuthenticated,
  selectTokenIsAlmostExpired,
  setUserDetails
} from "../store/slices/authUserSlice";
import { useSelector } from "react-redux";
import {store as reduxStore} from "../store/store";

const AUTH_TOKEN_PARAMETER_KEY = "t";

interface ProtectedRouteProps {
  redirectPathWhenUnauthenticated: string; // the path to redirect to when the user is not authenticated
}

/**
 * A route wrapper that checks if the user is authenticated. It also looks for a token in the URL and ingests it if found to authenticate the user.
 *
 * If a user is already logged in, a token doesn't need to be present in the URL.
 * But a token will override the current user if present.
 *
 * example:
 * https://aimply.com/dashboard?t=<TOKEN>
 *
 * TODO: once the present token is a refresh token, let's fetch a new access token (currently we directly expect an access token in the URL)
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirectPathWhenUnauthenticated,
}) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const token: string | null = searchParams.get(AUTH_TOKEN_PARAMETER_KEY);
  if (token) ingestPasswordlessAuthToken({accessToken: `Bearer ${token}`});

  const userIsAuthenticated = useSelector(selectAuthenticated);
  const userAccessTokenIsAlmostExpired = useSelector(selectTokenIsAlmostExpired);
  const accessToken = useSelector(selectAccessToken);

  // clear the token from the URL
  useEffect(() => {
    searchParams.delete(AUTH_TOKEN_PARAMETER_KEY);
    setSearchParams(searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (!userIsAuthenticated) navigate(redirectPathWhenUnauthenticated);
  }, [userIsAuthenticated]);

  // at every page navigation, let's check if the access token is almost expired
  // and refresh it if needed
  useEffect(() => {
    if (!(userAccessTokenIsAlmostExpired && userIsAuthenticated)) return;

    refreshToken(accessToken)
    .catch((error) => {
      console.error("Error refreshing token", error);
      });
      
  }, [userAccessTokenIsAlmostExpired, userIsAuthenticated, accessToken]);

  return userIsAuthenticated ? <Outlet /> : <></>;
};

export default ProtectedRoute;
