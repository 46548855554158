import axios from "axios";
import {getApiUrl, getWebsiteUrl} from "./environmentService";
import { store as reduxStore } from "../store/store";
import { setAccessToken, setUserDetails } from "../store/slices/authUserSlice";
import {AuthUser} from "../store/slices/authUserSlice";
import { purgeStore } from "../store/rootReducer";
import { selectUserEmail } from "../store/slices/authUserSlice";
import {jwtDecode} from "jwt-decode";

/**
 * Send a passwordless auth email to the given email address
 * and redirect to the given URL after the user clicks the link
 *
 * This is the equivalent of a sign in (not a sign up)
 */
export const loginWithPasswordlessAuth = async ({
  email,
  redirectUrl,
}: {
  email: string;
  redirectUrl: string;
}) => {
  await axios.post(`${getApiUrl()}/auth/send_passwordless_auth_email`, {
    email,
    redirect_url: redirectUrl,
  });
};

/**
 * Send a passwordless auth email to the given email address
 * and redirect to the given URL after the user clicks the link
 *
 * This is the equivalent of a sign up (not a sign in)
 *
 * by default we want to redirect new users to the onboarding page
 */
const DEFAULT_NEW_USER_REDIRECT_URL = `${getWebsiteUrl()}/onboarding`;
export const registerWithPasswordlessAuth = async ({
  email,
  redirectUrl = DEFAULT_NEW_USER_REDIRECT_URL,
}: {
  email: string;
  redirectUrl?: string;
}) => {
  await axios.post(`${getApiUrl()}/auth/send_passwordless_auth_email`, {
    email,
    redirect_url: redirectUrl,
    is_new_user: true,
  });
};

/**
 * Place the token from a redirect URL into the local storage
 *
 * TODO: also fetch id and email from the backend and place them in the local storage
 */
export const ingestPasswordlessAuthToken = async ({
  accessToken,
}: {
  accessToken: string;
}) => {
  reduxStore.dispatch(setAccessToken(accessToken));
  // take the token from jwt and decode it for the email
  const decoded = jwtDecode(accessToken);
  if ('email' in decoded) {
    reduxStore.dispatch(setUserDetails({email: decoded.email as string}));
  }
};

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${getApiUrl()}/login`, {
      user: {email, password},
    });
    const {data} = response.data;
    const token = response.headers.authorization;

    reduxStore.dispatch(setUserDetails({email: data.user.email}));
    reduxStore.dispatch(setAccessToken(token));

    console.log("Login successful");
    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

/**
 * Refresh the access token for the current user using the current access token.
 * 
 * TODO: this is not how we should do token refresh logic. See the note in auth_layer_controller.rb
 */
export const refreshToken = async (accessToken: string) => {
  console.log("Refreshing token");
  axios.post(`${getApiUrl()}/auth/refresh_token`, {}, {
    headers: {Authorization: accessToken},
  })
  .then((response) => reduxStore.dispatch(setAccessToken(response.headers.authorization)))
  .then(_ => console.log("Token refreshed"));
};

export const getAuthUser = (): AuthUser => {
  return reduxStore.getState().authUser;
};

export const getCurrentUserEmail = (): string | undefined => {
  return selectUserEmail(reduxStore.getState());
};


// TODO add a refresh token

export const signup = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${getApiUrl()}/signup`, {
      user: {email, password},
    });
    return response.data.data;
  } catch (error: any) {
    throw new Error(error.response.data.status.message);
  }
};

export const logout = async () => {
  const accessToken = getAuthUser()?.accessToken;
  purgeStore();

  // TODO: logout is broken. Let's fix it.
  await axios
    .delete(`${getApiUrl()}/logout`, {
      headers: {Authorization: accessToken},
    })
    .catch(() => {})
};
