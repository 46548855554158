// src/components/Onboarding/steps/MultiSelectPills.tsx
import React from "react";
import {DetailCheckboxOption, OnboardingFormProps} from "../Types";
import CheckboxSVG from "./CheckboxSVG";
import {ReactComponent as RefreshIcon} from "../../../assets/icons/refresh.svg";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";

const DetailCheckbox: React.FC<{
  option: DetailCheckboxOption;
  handleSelection: (checkboxOption: DetailCheckboxOption) => void;
  index: number;
}> = ({option, handleSelection, index}) => {
  return (
    <div
      key={index}
      className="detail-checkbox"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => handleSelection(option)}>
        <div
          key="tags"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
          }}>
          {option.tags!.map((tag, tagIndex) => (
            <div key={tagIndex} className="tag">
              {tag}
            </div>
          ))}
        </div>

        <h3 key="label" style={{textAlign: "left"}}>
          {option.label}
        </h3>
        <p
          key="value"
          style={{
            textAlign: "left",
            margin: "0",
          }}>
          {option.value}
        </p>
      </div>

      <div
        key="icons"
        className="detail-checkbox-icons"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
          height: "100%",
        }}>
        <CheckboxSVG
          key="checkbox"
          isChecked={option.isChecked || false}
          handleOnClick={() => handleSelection(option)}
        />
        <div
          key="edit-refresh"
          style={{
            display: "flex",
            gap: "20px",
          }}>
          <RefreshIcon className="emphasis-icon" key="refresh-icon" />
          <EditIcon className="emphasis-icon" key="edit-icon" />
        </div>
      </div>
    </div>
  );
};

const DetailCheckboxes: React.FC<OnboardingFormProps> = ({
  fieldKey,
  formData,
  onChange,
  detailCheckboxOptions,
}) => {
  const handleSelection = (eventValue: DetailCheckboxOption) => {
    eventValue.isChecked = !eventValue.isChecked;
    onChange(fieldKey!, formData[fieldKey!]);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}>
      {detailCheckboxOptions!.map((option, index) => (
        <DetailCheckbox
          key={index}
          option={option}
          handleSelection={handleSelection}
          index={index}
        />
      ))}
    </div>
  );
};

export default DetailCheckboxes;
