export const getApiUrl = (): string => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:3001";
  }
  return "https://aimply-webapp-api-499b77f2f271.herokuapp.com";
};

export const getApiUrlWithVersion = (version: string = "1"): string => {
  return `${getApiUrl()}/api/v${version}`;
};

export const getWebsiteUrl = (): string => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:3000";
  }

  return "https://app.aimply.io";
};


export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const STATSIG_API_KEY = process.env.REACT_APP_STATSIG_CLIENT_SDK_API_KEY!;
