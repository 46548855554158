/** Root reducer. */
import authUserSlice, { purgeAuthUser } from "./slices/authUserSlice";
import {combineReducers} from "redux";
import { store } from "./store";

const rootReducer = combineReducers({
  authUser: authUserSlice,
});

export default rootReducer;

export const purgeStore = () => {
  // This is king of an hack. We should normally just purge the persistor but the purge action doesn't work. 
  localStorage.clear(); 
  // we need this to ensure the app re-renders back to an unprotected route
  store.dispatch(purgeAuthUser());
};
