import React, {useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { selectAuthenticated, selectUserId } from "../store/slices/authUserSlice";
import getOnboardingRecorder from "../integrations/screenRecording";

const ScreenRecordingContext = ({children}: {children: React.ReactNode}) => {
    const location = useLocation();
    const previousLocationRef: React.MutableRefObject<string | undefined> = useRef(undefined);

    const userId = useSelector(selectUserId);   
    const isAuthenticated: boolean = useSelector(selectAuthenticated);

    // let's start the screen recording when user navigates to /onboarding
    // and stop it when they navigate away from /onboarding
    useEffect(() => {
        const currentLocation: string = location.pathname;
        const previousLocation: string = previousLocationRef.current ?? "";
        const pathHasChanged: boolean = currentLocation !== previousLocation;

        // do nothing if the path hasn't changed
        if (!pathHasChanged) return;

        // start the screen recording
        if (currentLocation === "/onboarding" && isAuthenticated) {
            const onboardingRecorder = getOnboardingRecorder(userId!);
            if (onboardingRecorder.hasStarted()) return;
            onboardingRecorder.start();
        }

        // stop the screen recording
        else if (previousLocation === "/onboarding") {
            const onboardingRecorder = getOnboardingRecorder(userId!);
            if (!onboardingRecorder.hasStarted()) return;
            onboardingRecorder.stop();
        }

        // update the previous location
        previousLocationRef.current = currentLocation;

    }, [location]);

    return <>{children}</>;
}

export default ScreenRecordingContext;