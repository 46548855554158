import React from "react";
import {Provider} from "react-redux";
import {persistor, store} from "../store/store";
import { PersistGate } from 'redux-persist/integration/react'

/**
 * A context wrapper for the Redux store. 
 * 
 * Persist gate makes sure we store the data in local storage. This is especially useful for saving auth tokens.
 */
export const ReduxStateProvider = ({children}: {children: React.ReactNode}) => {
  return <Provider store={store}><PersistGate loading={null} persistor={persistor}>{children}</PersistGate></Provider>;
};
