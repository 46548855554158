// src/components/Onboarding/index.tsx
import React, {useEffect, useState} from "react";
import WebsiteStep from "./steps/WebsiteStep";
import AudienceOfOne from "./steps/AudienceOfOne";
import ContentTypes from "./steps/ContentTypes";
import EventSampleContent from "./steps/EventSampleContent";
import {CONTENT_TYPES} from "./Constants";
import NewsSampleContent from "./steps/NewsSampleContent";
import JobsSampleContent from "./steps/JobsSampleContent";
import TestimonialsSampleContent from "./steps/TestimonialsSampleContent";
import {useLocation, useNavigate} from "react-router-dom";
import NewsletterEdition, {TemplateEditorProps} from "../NewsletterEdition";
import {FormDataObject, OnboardingFormProps} from "./Types";
import LoadingStep from "./steps/LoadingStep";

const OnboardingFlow: React.FC = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showLoadingStep, setShowLoadingStep] = useState<boolean>(false);

  const routerLocation = useLocation();

  const [formData, setFormData] = useState(() => {
    // TODO load from backend as well
    const storedFormData = localStorage.getItem("formData");
    return storedFormData
      ? (JSON.parse(storedFormData) as FormDataObject)
      : {
          email: "",
          companyWebsite: "",
          audienceOfOne: "",
          contentTypes: [] as CONTENT_TYPES[],
          sampleEvents: [],
          sampleNews: [],
          sampleTestimonials: [],
          sampleJobs: [],
        };
  });

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const handleChange = (field: string, value: string | string[]) => {
    // TODO save to backend as well
    setFormData(prev => ({...prev, [field]: value}));
  };

  const contentTypeToDynamicStep: Record<CONTENT_TYPES, JSX.Element> = {
    [CONTENT_TYPES.EVENTS]: (
      <EventSampleContent
        key="eventSample"
        formData={formData}
        onChange={handleChange}
        breadcrumbLabel="Event Samples"
      />
    ),
    [CONTENT_TYPES.NEWS]: (
      <NewsSampleContent
        key="newsSample"
        formData={formData}
        onChange={handleChange}
        breadcrumbLabel="News Samples"
      />
    ),
    // TODO Add remaining components
    [CONTENT_TYPES.TESTIMONIALS]: (
      <TestimonialsSampleContent
        key="testimonialsSample"
        formData={formData}
        onChange={handleChange}
        breadcrumbLabel="Testimonial Samples"
      />
    ),
    [CONTENT_TYPES.JOBS]: (
      <JobsSampleContent
        key="jobsSample"
        formData={formData}
        onChange={handleChange}
        breadcrumbLabel="Job Samples"
      />
    ),
  };

  const steps = [
    <WebsiteStep
      key="website"
      formData={formData}
      onChange={handleChange}
      breadcrumbLabel="Website"
    />,
    <AudienceOfOne
      key="audience"
      formData={formData}
      onChange={handleChange}
      breadcrumbLabel="Audience"
    />,
    <ContentTypes
      key="contentTypes"
      formData={formData}
      onChange={handleChange}
      breadcrumbLabel="Content Types"
    />,
    ...formData.contentTypes
      .filter(contentType => contentType in contentTypeToDynamicStep)
      .map(
        contentType => contentTypeToDynamicStep[contentType as CONTENT_TYPES],
      ),
    <NewsletterEdition
      key="newsletterEdition"
      onboardingProps={{
        formData,
        onChange: handleChange,
        breadcrumbLabel: "Review Template",
      }}
    />,
  ] as React.ReactElement<OnboardingFormProps | TemplateEditorProps>[];

  const updateStep = (newStep: number) => {
    // when navigating to the last step, we'll show a dummy loading step for few seconds
    const LOADING_TIME_MS = 3000;
    if (newStep === steps.length - 1) {
      setShowLoadingStep(true);

      setTimeout(() => {
        setShowLoadingStep(false);
        setCurrentStep(newStep);
        navigate(`?step=${newStep}`);
      }, LOADING_TIME_MS);
      return;
    }

    setCurrentStep(newStep);
    navigate(`?step=${newStep}`);
  };

  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      updateStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      updateStep(currentStep - 1);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      goToNextStep();
    }
  };

  function onFinalSample() {
    let numberOfUnfilledSamples = 0;
    for (const contentType of formData.contentTypes) {
      // switch on the content type
      switch (contentType) {
        case CONTENT_TYPES.EVENTS:
          if (formData.sampleEvents.length === 0) {
            numberOfUnfilledSamples += 1;
          }
      }
    }
    return numberOfUnfilledSamples > 1;
  }

  function atFormSubmission() {
    // we're past the last step in steps
    return (
      currentStep > steps.length - 2 &&
      // at least 1 content type is sleected
      formData.contentTypes.length > 0
    );
    // all content types have associated sample data
    // onFinalSample();
  }

  /**
   * Generate a sample newsletter edition with the collected context and navigate to it.
   */
  const handleFinish = () => {
    // TODO: pass the entire context collected during the onboarding steps
    navigate(`/dashboard`);
  };

  function getNextButton(): JSX.Element {
    if (atFormSubmission()) {
      return (
        <button className="secondary-button" onClick={handleFinish}>
          Finish
        </button>
      );
    }

    return (
      <button className="secondary-button" onClick={goToNextStep}>
        Next →
      </button>
    );
  }

  useEffect(() => {
    // Update current step based on URL change
    const step = parseInt(
      new URLSearchParams(routerLocation.search).get("step") || "0",
    );
    setCurrentStep(step);
  }, [routerLocation.search]);

  function getBreadcrumbLabel(index: number) {
    // Switch on steps[index] props type
    if (steps[index].type === NewsletterEdition) {
      return "Review Sample Newsletter";
    } else {
      return (steps[index].props as OnboardingFormProps).breadcrumbLabel;
    }
  }

  return (
    <div
      onKeyPress={handleKeyPress}
      tabIndex={0}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        // height: '100%',
        margin: "0 auto",
      }}>
      <div className="breadcrumb-container">
        <ul className="breadcrumb-list">
          {steps.map((step, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${index < currentStep ? "completed" : ""} ${index === currentStep ? "current" : ""}`}>
              <span
                className="onboarding-breadcrumb"
                onClick={() => updateStep(index)}>
                {getBreadcrumbLabel(index)}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          height: "80%",
          margin: "0 auto",
          gap: "2em",
        }}>
        {showLoadingStep ? <LoadingStep /> : steps[currentStep]}
        {!showLoadingStep && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2em",
                marginTop: "20px",
              }}>
              {currentStep > 0 && (
                <button className="tertiary-button" onClick={goToPreviousStep}>
                  ← Back
                </button>
              )}
              {getNextButton()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingFlow;
