// src/components/Onboarding/steps/NewsSampleContent.tsx
import React from "react";
import {OnboardingFormProps} from "../Types";
import DetailCheckboxes from "./DetailCheckboxes";
import onboardingService from "../../../services/onboardingService";

const NewsSampleContent: React.FC<OnboardingFormProps> = ({
  formData,
  onChange,
}) => (
  <div>
    <h2>Select Sample News 🗞️</h2>
    <p>Select the sample news that your audience might like.</p>
    <DetailCheckboxes
      fieldKey="sampleNews"
      formData={formData}
      onChange={onChange}
      detailCheckboxOptions={onboardingService.generateSampleNews()}
    />
  </div>
);

export default NewsSampleContent;
