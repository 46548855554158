import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {NewsletterEdition} from "../types/newsletterEdition";
import campaignService from "../services/campaignService";
import SidePanel from "./SidePanel";
import EditionLinks from "./EditionLinks";

/**
 * Call the API to fetch all newsletter editions that belong to the current user.
 */
const fetchNewsletterEditions = async (): Promise<NewsletterEdition[]> => {
  return campaignService.listNewsletterEditions();
};

const toRecurringRepresentation = (num: number) => {
  if (num === 1) return "Once";
  if (num === 2) return "Twice";
  return `${num} times`;
};

const Posts: React.FC = () => {
  const navigate = useNavigate();
  const [newsletterEditions, setNewsletterEditions] = useState<
    NewsletterEdition[]
  >([]);

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [activeEditionId, setActiveEditionId] = useState("");

  const [intervalUnit, setIntervalUnit] = useState("");
  const [intervalValue, setIntervalValue] = useState(0);

  const deleteNewsletterEdition = async (
    newsletterEditionId: string,
  ): Promise<any> => {
    await campaignService.deleteNewsletterEdition(newsletterEditionId);
    // fetch the updated list of newsletter editions after deletion
    fetchNewsletterEditions().then(setNewsletterEditions);
  };

  useEffect(() => {
    fetchNewsletterEditions().then(setNewsletterEditions);
  }, []);

  if (newsletterEditions.length > 0) {
    // today, scheduling fields live on individual editions
    const edition = newsletterEditions[0];
    if (!intervalUnit && !intervalValue) {
      setIntervalUnit(edition.interval_unit!);
      setIntervalValue(edition.interval_value!);
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <h1>Posts</h1>

        <button
          type="submit"
          className="primary-button"
          onClick={() => navigate("/newsletterEdition/new")}>
          Create manual post
        </button>
      </div>

      <p style={{marginTop: "0"}}>
        Create new posts, view existing posts, and edit templates.
      </p>

      {intervalUnit && intervalValue && (
          <div
              className="primary-input-container recurring-info"
              style={{
                  paddingTop: "4px",
                  maxWidth: "400px",
              }}>
              <h3>Schedule</h3>
              {`${toRecurringRepresentation(intervalValue)} per ${intervalUnit}`} ℹ️
              <br/>
              <br/>
              Next arrival: {
            // next week from today
            new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString()
          } ℹ️
          </div>
      )}

      <ul className="newsletter-list">
        {newsletterEditions.map(newsletterEdition => (
            <li
                key={newsletterEdition.id}
                className="newsletter-item"
                onClick={() =>
                    navigate(`/newsletterEdition/${newsletterEdition.id}`)
                }>
              <div className="newsletter-item-content-left">
              <span className="newsletter-title">
                {'Sample Newsletter'}
              </span>
                <span className="newsletter-status">
                Done
              </span>
              </div>

              <div>
              <span
                  style={{
                    marginRight: "16px",
                    padding: "10px 20px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "10px",
                    color: "#333",
                    fontSize: "0.85rem",
                    display: "inline-block",
                    minWidth: "120px",
                    textAlign: "center",
                  }}>
                Arrival:{" "}
                {new Date(
                    newsletterEdition.newsletter_timestamp,
                ).toLocaleDateString()}
              </span>

                <button
                    className="tertiary-button"
                    style={{
                      marginRight: "16px",
                    }}
                    onClick={e => {
                      e.stopPropagation(); // short-circuit the parent's `onClick` behavior
                      setIsSidePanelOpen(true);
                      setActiveEditionId(newsletterEdition.id);
                    }}>
                  Add links
                </button>

                <button
                    className="tertiary-button"
                    style={{
                      marginRight: "16px",
                    }}
                    onClick={e => {
                      e.stopPropagation(); // short-circuit the parent's `onClick` behavior
                      navigate(`/newsletterEdition/${newsletterEdition.id}/links`);
                    }}>
                  Give feedback
                </button>

                <button
                    style={{
                      backgroundColor: "#fff",
                      color: "#d9534f",
                      border: "1px solid #d9534f",
                      borderRadius: "10px",
                      padding: "10px 20px",
                      cursor: "pointer",
                      marginRight: "16px",
                    }}
                    onClick={e => {
                      e.stopPropagation(); // short-circuit the parent's `onClick` behavior

                      const confirmed = window.confirm(
                          "Are you sure you want to delete this post? This action cannot be undone.",
                      );
                      if (confirmed) {
                        deleteNewsletterEdition(newsletterEdition.id);
                      }
                    }}>
                  Delete
                </button>
              </div>
            </li>
        ))}
      </ul>

      <SidePanel isOpen={isSidePanelOpen} onClose={() => setIsSidePanelOpen(false)}>
        <EditionLinks newsletterEditionId={activeEditionId} />
      </SidePanel>
    </div>
  );
};

export default Posts;
