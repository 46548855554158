// src/components/Onboarding/steps/EventSampleContent.tsx
import React from "react";
import {OnboardingFormProps} from "../Types";
import DetailCheckboxes from "./DetailCheckboxes";
import onboardingService from "../../../services/onboardingService";
import {useSelector} from "react-redux";
import {selectUserEmail} from "../../../store/slices/authUserSlice";

const EventSampleContent: React.FC<OnboardingFormProps> = ({
  formData,
  onChange,
}) => {

    const userEmail = useSelector(selectUserEmail);

    return (
        <div>
            <h2>Select Sample Events 📆</h2>
            <p>Select the sample events that your audience might like.</p>
            <DetailCheckboxes
                fieldKey="sampleNews"
                formData={formData}
                onChange={onChange}
                detailCheckboxOptions={onboardingService.generateSampleEvents()}
            />
        </div>
    );
}

export default EventSampleContent;
