// src/components/Notification.tsx
import React from "react";
import {ReactComponent as NotificationIcon} from "../../assets/icons/notification.svg";

interface NotificationProps {
  count?: number; // Optional notification count
  onClick?: () => void; // Optional click handler for the icon
}

const Notification: React.FC<NotificationProps> = ({count = 0, onClick}) => {
  return (
    <div style={styles.container} onClick={onClick}>
      <NotificationIcon style={styles.icon} />
      {count > 0 && <span style={styles.badge}>{count}</span>}
    </div>
  );
};

// Inline styles for the component (or you can use CSS modules or styled-components)
const styles = {
  container: {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    marginRight: "14px",
  } as React.CSSProperties,
  icon: {
    width: "24px",
    height: "24px",
  } as React.CSSProperties,
  badge: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "4px 6px",
    fontSize: "12px",
    fontWeight: "bold",
  } as React.CSSProperties,
};

export default Notification;
