// src/components/Onboarding/steps/Step3.tsx
import React, {useEffect, useRef} from "react";
import {OnboardingFormProps} from "../Types";

const AudienceOfOne: React.FC<OnboardingFormProps> = ({formData, onChange}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="primary-input-container">
      <h2>Ideal Reader</h2>
      <p>
        Describe your ideal reader. Be as specific as possible (age, job,
        location, industry).
      </p>
      <input
        ref={inputRef}
        type="text"
        value={formData.audienceOfOne}
        onChange={e => onChange("audienceOfOne", e.target.value)}
        placeholder="A US-based Chief Information Officer (CIO) buying enterprise security software."
        className="primary-input"
      />
    </div>
  );
};

export default AudienceOfOne;
