// src/components/Onboarding/steps/Step3.tsx
import React from "react";
import {OnboardingFormProps} from "../Types";
import {ONBOARDING_ACCORDION_CONTENT} from "../Constants";
import Accordion from "./Accordion";

const ContentTypes: React.FC<OnboardingFormProps> = ({formData, onChange}) => {
  return (
    <div>
      <h2>Teach Us About Your Audience</h2>
      <p>Select the content type(s) that your audience might like.</p>
      <Accordion
        fieldKey="contentTypes"
        formData={formData}
        onChange={onChange}
        accordionOptions={ONBOARDING_ACCORDION_CONTENT}
      />
    </div>
  );
};

export default ContentTypes;
