import {DetailCheckboxOption} from "./Types";

export const SAMPLE_EVENTS_CIO = [
  {
    value:
      "Join us for an after work happy hour with the NYC AI community, ElevenLabs and Air Street Capital.",
    source: "https://lu.ma/aimixernyc?tk=YMY6YU",
    label: "Air Street x ElevenLabs NYC AI happy hour",
    tags: ["AI", "Artificial Intelligence"],
  },
  {
    value:
      "Join the NY Enterprise Tech Meetup to learn from top product leaders in enterprise software, featuring insights on accelerating product growth through velocity, collaboration, and experimentation.",
    source: "https://lu.ma/NYETMOctober24?tk=xXQkYl",
    label:
      "NY Enterprise Tech Meetup: How Ramp & Figma Built World-Class Products",
    tags: ["Enterprise Tech"],
  },
  {
    value:
      "NextView Ventures is hosting the Future Founder Soirée, an early evening happy hour aimed at aspiring entrepreneurs seeking co-founders, accountability partners, and inspiration.",
    source: "https://lu.ma/ub3bnrfy?tk=BnMOTA",
    label: "NextView Ventures Future Founder Soirée",
    tags: ["Startups", "Early Stage Founders"],
  },
  {
    value:
      "The AI Furnace hosted a GenAI Companies showcase in NYC, featuring demos from innovative startups Artisan AI and Velvet.",
    source: "https://lu.ma/yvg8qq5m?tk=kWzsfD",
    label: "NYC 🗽 GenAI Companies Building in NYC",
    tags: ["AI", "Startups"],
  },
];

// NOTE: I used max 45 words for these
export const SAMPLE_NEWS_CIO = [
  {
    value:
      "As generative AI reshapes workplaces, organizations are encouraged to shift from traditional job title-based hiring to a skills-based approach, focusing on adaptable skillsets to remain agile. This transition will enable companies to leverage AI efficiently, freeing human workers for more complex, value-added tasks and enhancing workforce agility.",
    label: "CIOs prioritize reskilling employees to embrace AI",
    source:
      "https://www.cio.com/article/2509754/generative-ai-and-preparing-for-a-shift-to-skills-based-hiring.html",
    tags: ["AI"],
  },
  {
    value:
      "Nvidia is expanding AI operations in India, partnering with major tech firms like Infosys and Tata Communications to boost data center capacity and train 500,000 developers. This push leverages India's IT ecosystem for AI applications, amid the U.S.'s semiconductor restrictions on China.",
    label: "Nvidia Taps India's Growing AI Market",
    source: "",
    tags: ["AI", "Semiconductors"],
  },
  {
    value:
      "Apple unveiled Apple Intelligence at WWDC 2024, integrating AI into existing products rather than creating standalone features. The platform will enhance apps like Messages, Mail, and Siri, with features like text generation, image creation, and ChatGPT integration, rolling out initially in October via iOS 18.1 and expanding further in iOS 18.2 with additional tools.",
    label: "What is Apple Intelligence, when is it coming and who will get it?",
    source: "",
    tags: ["AI"],
  },
  {
    value:
      "The U.S. Cybersecurity and Infrastructure Security Agency (CISA) has flagged a high-severity vulnerability in Microsoft SharePoint (CVE-2024-38094), actively exploited by attackers to achieve remote code execution. Federal agencies must patch by November 12, 2024, as threat actors use public proof-of-concept exploits for initial access.",
    label:
      " CISA highlights active exploitation of Microsoft SharePoint vulnerability",
    source:
      "https://thehackernews.com/2024/10/cisa-warns-of-active-exploitation-of.html",
    tags: ["Software Security", "Vulnerabilities", "Microsoft"],
  },
];

// TODO - I'm actually not going to show the community content in the demo, so this is just a placeholder
//  We need to figure out a clear way to show types of community content in the demo - I'm not sure it's the same as news/jobs/events
export const COMMUNITY_CONTENT_CIO = [
  {
    value:
      "The rapid evolution of AI technologies has changed the game for CIOs. With AI now embedded across various enterprise functions—from predictive maintenance to customer service—CIOs are expected to lead AI strategy discussions at the executive level.\n - Author: Jamie Martinez, Senior IT Consultant",
    label:
      "Community Submission: How Emerging AI Tools Are Reshaping the CIO Role",
    source: "",
    tags: ["AI"],
  },
  {
    value:
      "As our company grew, we needed a cloud infrastructure that could scale quickly. The consulting firm made our environment more agile, enabling us to handle business demands efficiently, without any performance or security compromises.\n - Author: John Locke, CIO",
    label: "Testimonial: Cloud Infrastructure",
    source: "",
    tags: ["Cloud Infrastructure"],
  },
  {
    value:
      "Implementing AI tools like ChatGPT in the workplace offers significant potential, but safety and data security remain paramount. We have a responsibility to ensure that any AI tool we adopt complies with our data protection policies and aligns with our business objectives.\n - Author: Michael Smith, CIO",
    label: "Q&A: Do you think it's safe to have ChatGPT in your workplace?",
    source: "",
    tags: ["AI", "IT"],
  },
  {
    value:
      "Thank you for inviting me to contribute commentary on such a timely topic. AI is rapidly transforming how we approach both everyday tasks and strategic goals in the workplace, and I’m excited to share my perspective.\n - Author: Dana Jackson, CIO",
    label: "Event Commentary: AI in the Workplace",
    source: "",
    tags: ["AI", "Events"],
  },
];

export const SAMPLE_JOBS_CIO = [
  {
    value:
      "The Chief Information Officer (CIO) will provide leadership, vision, and direction in the development and implementation of technology initiatives that align with the strategic goals of Mountain West Insurance & Financial Services.",
    label: "Chief Information Officer - Mountain West Insurance",
    source: "",
    tags: ["IT", "Leadership"],
  },
  {
    value:
      "Lead the strategy and execution for on-site personalization features (e.g. product recommendation), ensuring they deliver value from ideation through optimization6",
    label: "Lead Product Manager, Personalization - Attentive",
    source: "",
    tags: ["AI", "Product Management"],
  },
  {
    value:
      "The Chief Digital Information Officer plays a critical role in managing the technology infrastructure and systems that support the organization's clinical, financial, and administrative operations",
    label: "Chief Digital Information Officer - AMN",
    source: "",
    tags: ["IT", "Leadership"],
  },
  {
    value:
      "Java Lead/Sr. Software Engineer position in NY/NJ or LA, offering up to $140K annually. Responsibilities include backend development in Java/Scala, building CI/CD pipelines, implementing monitoring tools, and integrating automated incident response with PagerDuty.",
    label: "Java Lead/Sr. Software Engineer - Dice",
    source: "",
    tags: ["AI"],
  },
];

export const PREPPED_SAMPLE_DATA: Record<string, Record<string, DetailCheckboxOption[]>> = {
  "evan@aimply.io": {
    events: [
      {
        value:
          "[TEST FOR EVAN] Join us for an after work happy hour with the NYC AI community, ElevenLabs and Air Street Capital.",
        source: "https://lu.ma/aimixernyc?tk=YMY6YU",
        label: "Air Street x ElevenLabs NYC AI happy hour",
        tags: ["AI", "Artificial Intelligence"],
      }
    ],
    news: SAMPLE_NEWS_CIO,
    jobs: SAMPLE_JOBS_CIO,
    communityContent: COMMUNITY_CONTENT_CIO
  }
}

