import React, { useState, useEffect } from 'react';

export const onboardingSteps = [
    // TODO - we'll dump the hard coded positioning once the demo is over
    {
        title: "Adjust your sample newsletter",
        positionTop: "10%",
        positionLeft: "50%",
        description: `
        <ul>
            <li>We will base the future newsletters we build for you off of this sample.</li>
        </ul>
        `
    },
    {
        positionTop: "60%",
        positionLeft: "47%",
        title: "Use the content blocks here",
        description: `
<ul>
            <li>Add or remove any content from this newsletter to align it with your audience's taste.</li>
            <li>Don't worry if the content is fresh, this newsletter is just a sample.</li>
            <li>We will use the design here to build your future newsletters.</li>
</ul>
        `
    },
    {
        positionTop: "70%",
        positionLeft: "45%",
        title: "Approve the sample newsletter",
        description: `
<ul>
            <li>Once you're happy with the sample, click "Finish".</li>
            <li>You can change your sample newsletter at any time and we will adjust your future newsletters accordingly.</li>
</ul>
        `
    }
];

const OnboardingPopup: React.FC<{
    onClose: () => void;
    currentStep: number;
    onNext: () => void;
    onPrevious: () => void;
}> = ({ onClose, currentStep, onNext, onPrevious }) => {
    const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');

    useEffect(() => {
        setSlideDirection(currentStep > 0 ? 'right' : 'left');
    }, [currentStep]);

    return (
        <div
            className={`onboarding-popup ${slideDirection}`}
            style={{
                top: onboardingSteps[currentStep].positionTop,
                left: onboardingSteps[currentStep].positionLeft
            }}
        >
            <h2>{onboardingSteps[currentStep].title}</h2>
            <p dangerouslySetInnerHTML={{__html: onboardingSteps[currentStep].description}}/>
            <div style={{marginTop: '20px', display: 'flex', justifyContent: 'space-between'}}>
                {currentStep > 0 && (
                    <button onClick={() => {
                        setSlideDirection('left');
                        onPrevious();
                    }} disabled={currentStep === 0} className="tertiary-button">
                        ← Back
                    </button>
                )}
                <div style={{marginLeft: 'auto'}}>
                    {currentStep < onboardingSteps.length - 1 ? (
                        <button onClick={() => {
                            setSlideDirection('right');
                            onNext();
                        }} className="tertiary-button">Next →</button>
                    ) : (
                        <button onClick={onClose} className="tertiary-button">Close</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OnboardingPopup;
