// src/components/Onboarding/steps/JobsSampleContent.tsx
import React from "react";
import {OnboardingFormProps} from "../Types";
import DetailCheckboxes from "./DetailCheckboxes";
import onboardingService from "../../../services/onboardingService";

const JobsSampleContent: React.FC<OnboardingFormProps> = ({
  formData,
  onChange,
}) => (
  <div>
    <h2>Select Jobs 🔨</h2>
    <p>Select the sample jobs that your audience might want to apply to.</p>
    <DetailCheckboxes
      fieldKey="sampleJobs"
      formData={formData}
      onChange={onChange}
      detailCheckboxOptions={onboardingService.generateSampleJobs()}
    />
  </div>
);

export default JobsSampleContent;
