import React, {useEffect, useState} from "react";

export interface TemplateEditorSaveModalProps {
  downloadAsHtml: () => void;
  downloadAsConvertkitHtml: () => void;
  onClose: () => void;
  allowExport: boolean; // we need a way to know if we should let the user export the template or not
  // we need this because the BeeFree plugin calls the onSave callback asynchronously, and if we click "download as HTML" before it's done, it'll download the older version of the template
}

/**
 * This is the modal that comes up when we click "save as template"
 *
 * It has different options on exporting the template:
 * - as an HTML file
 * - as an HTML file that is compatible with Convertkit: https://help.kit.com/en/articles/2810363-creating-a-custom-html-email-template
 */
const TemplateEditorSaveModal: React.FC<TemplateEditorSaveModalProps> = ({
  downloadAsHtml,
  downloadAsConvertkitHtml,
  onClose,
  allowExport,
}) => {
  const [buttonsAreDisabled, setButtonsAreDisabled] = useState(false);

  useEffect(() => setButtonsAreDisabled(!allowExport), [allowExport]);

  return (
    <div
        className="primary-input-container"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}>
      <h2>Save as template</h2>
      {buttonsAreDisabled ? <p>Loading...</p> : null}
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "20px",
            }}>
            <button className="tertiary-button"
                    onClick={downloadAsHtml} disabled={buttonsAreDisabled}>
                Download as HTML
            </button>
            <button className="tertiary-button"
                    onClick={downloadAsConvertkitHtml}
                    disabled={buttonsAreDisabled}>
                Download as ConvertKit HTML
            </button>
            <button className="tertiary-button"
                    onClick={downloadAsConvertkitHtml}
                    disabled={buttonsAreDisabled}>
                Download as Hubspot HTML
            </button>
            <button className="tertiary-button"
                    onClick={downloadAsConvertkitHtml}
                    disabled={buttonsAreDisabled}>
                Download as Beehiiv HTML
            </button>
            <button className="tertiary-button"
                    onClick={onClose} style={{marginTop: "10px"}}>
                Close
            </button>
        </div>
    </div>
  );
};

export default TemplateEditorSaveModal;
