import React from "react";
import flyingPaperPlaneGif from "../../../assets/animations/flying_paper_plane.gif";

/**
 * This is not a "real step" compared to the other steps in the onboarding flow.
 * It's just a placeholder to show a loading state.
 */
const LoadingStep: React.FC = () => {
  return (
    <div>
      <h2>Almost there...</h2>
      <h3>We're creating your first newsletter edition...</h3>
      <img src={flyingPaperPlaneGif} alt="Flying paper plane" />
    </div>
  );
};

export default LoadingStep;
