import React, {useState} from "react";
import {OnboardingFormProps} from "../Types";
import {CONTENT_TYPES} from "../Constants";
import CheckboxSVG from "./CheckboxSVG";

function getOnChange(
  fieldKey: string,
  onChange: (field: string, value: string | string[]) => void,
  updatedValues: string[],
) {
  onChange(fieldKey, updatedValues);
}

const Accordion: React.FC<OnboardingFormProps> = ({
  fieldKey,
  formData,
  onChange,
  accordionOptions,
}) => {
  const [activePanel, setActivePanel] = useState<string | null>(null);

  const handleAccordionClick = (eventLabel: string) => {
    const oldValues = formData[fieldKey!] as string[];

    const updatedValues = oldValues.includes(eventLabel)
      ? oldValues.filter(value => value !== eventLabel)
      : [...oldValues, eventLabel];

    getOnChange(fieldKey!, onChange, updatedValues);

    // Toggle the clicked panel; if it's already active, close it.
    setActivePanel(prev => (prev === eventLabel ? null : eventLabel));
  };

  function handleCheckboxClick(label: CONTENT_TYPES) {
    if (accordionOptions) {
      accordionOptions[label].isChecked = !accordionOptions[label].isChecked;
      formData.contentTypes = Object.keys(accordionOptions)
        .filter(key => accordionOptions[key as CONTENT_TYPES].isChecked)
        .map(key => key as CONTENT_TYPES);
    }
    onChange(fieldKey!, formData[fieldKey!]);
  }

  return (
    <div className="accordion">
      {accordionOptions &&
        Object.keys(accordionOptions).length > 0 &&
        Object.keys(accordionOptions).map((key: string, index: number) => {
          const option = accordionOptions[key as CONTENT_TYPES];
          const isActive = activePanel === option.label;
          const isLastOption =
            index === Object.keys(accordionOptions).length - 1;
          const isChecked =
            accordionOptions[key as CONTENT_TYPES].isChecked || false;

          return (
            <div
              key={option.label}
              style={{
              }}>
              <div className="accordion-header" style={{}}>
                <CheckboxSVG
                  isChecked={isChecked}
                  handleOnClick={() =>
                    handleCheckboxClick(key as CONTENT_TYPES)
                  }
                />
                <span
                  className="accordion-label"
                  style={{cursor: "pointer"}}
                  onClick={() => handleAccordionClick(option.label)}>
                  {option.label}
                </span>
                <span
                  className={`accordion-arrow ${isActive ? "active" : ""}`}
                  style={{cursor: "pointer"}}
                  onClick={() => handleAccordionClick(option.label)}>
                  ▼
                </span>
              </div>
              <div
                className={`accordion-content ${isActive ? "active" : ""}`}
                dangerouslySetInnerHTML={{__html: option.value}}
              />
              {!isLastOption && <div className="accordion-divider"></div>}
            </div>
          );
        })}
    </div>
  );
};

export default Accordion;
